<template>
  <div class="footer-block">
<!-- TODO: Чат с заказчиком -->
    <div @click="$emit('open-chat')" class="chat-button">
<!--      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:class="'chat-button__icon'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1.929.001H16.07C17.137.001 18 .864 18 1.93v10.285a1.928 1.928 0 01-1.929 1.929H13.79l.35 3.143a.643.643 0 01-1.07.549l-4.102-3.692H1.93A1.929 1.929 0 010 12.215V1.93C0 .864.863 0 1.929 0z" fill="#FF6B69"/></svg>-->
<!--      <div class="chat-button__text">Чат с заказчиком</div>-->
    </div>

    <slot name="action-btn"></slot>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>

.footer-block{
  padding: 9px 0px;
  //border-top: 1px solid rgba(163, 163, 163, 0.3);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.chat-button {
  align-items: center;
  display: flex;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: -0.41px;
  color: var(--color-prime);
  cursor: pointer;
}
.chat-button__icon {
  padding-right: 7px;
}


</style>

