<template>
  <div class="maps-wrapper">
    <yandex-map
      ref="ya-maps"
      :coords="coords"
      :zoom="12"
    >
      <template v-for="(request, index) in normalizeRequestList">
        <ymap-marker
          :key="index"
          :coords="request.address.mapsCoordinates"
          :marker-id="index"
          :icon="markerIcon(request)"
          @click="onMarkClick(request.id)"
        />
      </template>
      <ymap-marker
        key="currentUser"
        marker-id="currentUser"
        :coords="coords"
        :icon="userIconSettings"
      />
    </yandex-map>


    <v-dialog width="100%" max-width="900px" v-if="isVisibleDialogDetails" v-model="isVisibleDialogDetails">
      <vue-custom-scrollbar  class="custom-scrollbar" :settings="{ wheelPropagation: false }">
        <v-card>
          <div class="btn-close" @click="closeDialog">
            <svg width="12" height="11" viewBox="0 0 12 11" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1.008 9.375a.71.71 0 000 .992.71.71 0 00.992 0L6 6.36l4.008 4.008a.7.7 0 00.984 0 .696.696 0 000-.992l-4-4.008 4-4a.696.696 0 000-.992.7.7 0 00-.984 0L6 4.383 2 .375a.71.71 0 00-.992 0 .71.71 0 000 .992l4.008 4-4.008 4.008z"/></svg>
          </div>
          <ModalProviderReqestDetails v-if="selectedRequestId" :request-id="selectedRequestId"/>
        </v-card>
      </vue-custom-scrollbar>
    </v-dialog>
  </div>
</template>

<script>
import { loadYmap, yandexMap, ymapMarker } from '@library/ya-ma/index.js';
// import { loadYmap, yandexMap, ymapMarker } from 'st-yandex-maps';

import ModalProviderReqestDetails from '@component/Modal/ModalProviderReqestDetails.vue';
import { mapState } from 'vuex';

export default {
  components: {
    yandexMap,
    ymapMarker,
    ModalProviderReqestDetails
  },
  props: {
    requests: {
      type: Array,
      default() {
        return null;
      },
    },
    mapFilters: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  data: () => ({
    coords: [
      55.755819,
      37.617644
    ],
    selectedRequestId: null,
    isVisibleDialogDetails: false,
  }),
  computed: {
    ...mapState({
      user: state => state.user.user,
    }),
    normalizeRequestList() {
      return this.requests.reduce((result, item) => {
        // if (item?.request) {
          const request = item?.request && { ...item.request } || item;
          if (!item?.request?.mapsCoordinates) {
            request.address.mapsCoordinates = [
              item.address?.coordinates?.latitude,
              item.address?.coordinates?.longitude
            ];
          }
          result.push(request);
        // } else {
        //   result.push(item);
        // }
        return result;
      }, []);
    },
    userId() {
      return this.user.id;
    },
    userIconSettings() {
      return {
        layout: 'default#imageWithContent',
        imageHref: require('@img/maps/person-pin.svg'),
        imageSize: [34, 34],
        imageOffset: [-18, -12],
      };
    }
  },
  watch: {
    mapFilters: {
      handler() {
        this.updareSearchRadius();
      },
      deep: false,
    },
  },
  created() {
    this.getCurerntLocation();
  },
  methods: {
    async updareSearchRadius() {
      await loadYmap();
      var myCircle = new window.ymaps.Circle([
        this.coords,
        this.mapFilters?.distance*1000
      ], {}, {
        fillColor: "007bff0d",
        strokeColor: "007bff94",
        strokeWidth: 1
      });

      this.$refs['ya-maps'].replaceObject(myCircle);
    },
    onMarkClick(requestId) {
      this.isVisibleDialogDetails = true;
      this.selectedRequestId = requestId;
    },
    closeDialog() {
      this.isVisibleDialogDetails = false;
      this.selectedRequestId = null;
    },
    markerIcon(request) {
      let imageLink = request.creator.photo;
      imageLink = imageLink && /^https?:\/\//.test(imageLink)
        ? imageLink
        : imageLink === undefined || null
          ? undefined
          : process.env.VUE_APP_SERVER_API_URL + imageLink;

      const contentLayout = imageLink ?
        `
          <div style="width: 34px; height: 34px; border-radius: 50%; overflow: hidden;">
            <img style="width: 100%; height: 100%; object-fit: cover;" src="${imageLink}"/>
          </div>
        `
        : null;

      return {
        layout: 'default#imageWithContent',
        imageHref: require('@img/maps/map-pin.svg'),
        imageSize: [49, 80],
        imageOffset: [-25, -74],
        contentOffset: [8, 8],
        contentLayout,
      };
    },
    getCurerntLocation() {
      navigator.geolocation
        .getCurrentPosition(async result => {
          if (result) {
            this.coords = [result.coords.latitude, result.coords.longitude];
            console.log(this.coords);

            await RequestManager.User.updateCurrentLocation(this.userId, {
              coordinates: {
                latitude: result.coords.latitude,
                longitude: result.coords.longitude,
              }
            });
          }
        });
    }
  },
};
</script>

<style lang="scss" scoped>
.maps-wrapper {
  width: 100%;
  height: 100vh;
}

::v-deep {
  .ymap-container {
    height: 100vh;
  }
}

.custom-scrollbar {
  max-height: calc(90vh);
  overflow-y: scroll;
}
</style>
