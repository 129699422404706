<template>
  <div>
    <template v-if="loading">
      <v-progress-linear indeterminate></v-progress-linear>
    </template>
    <template v-else-if="!normalizedRequestsList.length">
      <div>Нет заявок</div>
    </template>
    <template v-else>
      <template v-for="request in normalizedRequestsList">
        <slot name="list-item" :item="request"></slot>
      </template>
      <InfiniteLoading
        class="infinite-element"
        :forceUseInfiniteWrapper="true"
        :distance="500"
        error="Opps, something went wrong. Reload page."
        @infinite="$emit('infinite', $event)"
      >
        <div slot="no-more" />
        <div slot="no-results" />
      </InfiniteLoading>
    </template>
  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';
import BlockPersonModel from '@component/Module/BlockPersonModel.js';

const normalizeRequestsList = (serviceRequestsList, statuses) => {
  return serviceRequestsList.map(requestData => {
    const request = requestData.request ? requestData.request : requestData;
    const status = statuses.find(el => el.key === request.status);
    const address = request.address.address;
    return {
      ...request,
      status,
      category: request.category.name,
      address,
      person: new BlockPersonModel({
        first_name: request.creator && request.creator.first_name,
        last_name: request.creator && request.creator.last_name,
        date: request.date,
        time: request.time,
        photo: request.creator && request.creator.photo,
      }),
    };
  });
};

export default {
  props: {
    list: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    requestsStatuses: {
      type: Array,
      required: true,
    },
  },
  components: {
    InfiniteLoading,
  },

  computed: {
    normalizedRequestsList() {
      return normalizeRequestsList(this.list, this.requestsStatuses);
    },
  },
};
</script>
<style lang="scss" scoped>
.infinite-element{
  width: 100%
}
</style>
