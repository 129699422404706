<template>
  <CardStatusAndBg
    :item="request"
    :status="request.status"
    :person="request.person"
    :description="request.about"
  >
    <template #body="{ item }">
      <div class="attrs-row">
        <CardItem class="attrs-row__item" :underline="false">
          <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:slot="'icon'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M3.021 6.41L8.785.646l.736.762-6.5 6.5L0 4.887l.762-.762 2.26 2.285z" fill="#0C72D6"/></svg>
          {{ item.category }}
        </CardItem>
        <CardItem class="attrs-row__item" :underline="false">
          <svg width="11" height="16" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:slot="'icon'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M5.5 0C2.436 0 0 2.48 0 5.6 0 9.76 5.5 16 5.5 16S11 9.76 11 5.6C11 2.48 8.564 0 5.5 0zm0 7.6c-1.1 0-1.964-.88-1.964-2s.864-2 1.964-2 1.964.88 1.964 2-.864 2-1.964 2z" fill="#0C72D6"/></svg>
          {{ item.distance }} км до места оказания услуги
        </CardItem>
      </div>
      <div class="attrs-row">
        <CardItem class="attrs-row__item" :underline="false">
          <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:slot="'icon'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.421 8.333v.556C10.421 9.5 9.9 10 9.263 10H1.158C.52 10 0 9.5 0 8.889V1.11C0 .5.521 0 1.158 0h8.105c.637 0 1.158.5 1.158 1.111v.556h-5.21c-.637 0-1.158.5-1.158 1.11v4.445c0 .611.52 1.111 1.158 1.111h5.21zm-5.21-1.11H11V2.777H5.21v4.444zm2.315-1.39c-.463 0-.868-.389-.868-.833 0-.444.405-.833.868-.833.463 0 .869.389.869.833 0 .444-.406.833-.869.833z" fill="#0C72D6"/></svg>
          Цена заказчика: {{ item.price }} руб./день
        </CardItem>
        <!-- TODO: Сейчас в системе нельзя установить цену исполнителя -->
        <!-- <CardItem class="attrs-row__item" :underline="false">
          <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:slot="'icon'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.421 8.333v.556C10.421 9.5 9.9 10 9.263 10H1.158C.52 10 0 9.5 0 8.889V1.11C0 .5.521 0 1.158 0h8.105c.637 0 1.158.5 1.158 1.111v.556h-5.21c-.637 0-1.158.5-1.158 1.11v4.445c0 .611.52 1.111 1.158 1.111h5.21zm-5.21-1.11H11V2.777H5.21v4.444zm2.315-1.39c-.463 0-.868-.389-.868-.833 0-.444.405-.833.868-.833.463 0 .869.389.869.833 0 .444-.406.833-.869.833z" fill="#0C72D6"/></svg>
          Цена исполнителя: 700 руб./день
        </CardItem> -->
      </div>
    </template>

    <template slot="footer">
      <slot name="footer"></slot>
    </template>
  </CardStatusAndBg>
</template>

<script>
import CardStatusAndBg from '@component/Module/PersoneCard/CardStatusAndBg';
import CardItem from '@component/Module/PersoneCard/CardItem';
export default {
  components: { CardStatusAndBg, CardItem },
  props: {
    request: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss">
.attrs-row{
  display: flex;
  border-bottom: 1px solid rgba(163, 163, 163, 0.3);
  flex-wrap: wrap;
  &__item{

         width: 100%;
    @include respond-to('md') {
       width: 50%;

    }
  }
}
</style>
