<template>
  <div>
    <YaMaps
      v-if="type === 'yandex'"
      v-bind="$attrs"
      v-on="$listeners"
    />
    <div v-else-if="type === 'google'">

    </div>
  </div>
</template>

<script>
import YaMaps from './YaMaps.vue';
export default {
  inheritAttrs: false,
  props: {
    type: {
      type: String,
      default: 'yandex',
    },
  },
  components: {
    YaMaps,
  },
};
</script>
