<template>
  <div class="page-wrap">
    <template v-if="view === 'Список'">
      <div class="page-content">
        <div class="container container--asside-content">
          <ProviderListWithFilters
            :provider="$ENUM.ASSISTANCE_PROVIDERS_TYPES.COMPANY"
            :map-filters="mapFilters"
            @get-requests="getRequests"
          >
            <template #viewSwitcher>
              <ListMapSwitch v-model="view"></ListMapSwitch>
            </template>
          </ProviderListWithFilters>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="page-map">
        <div class="page-map__content">
          <ListMapSwitch class="map-switcher" v-model="view"></ListMapSwitch>
          <ApplicationsMap :requests="requests" :map-filters="mapFilters"/>
        </div>
        <div :class="['page-map__asside', { 'page-map__asside--shown': assideShown }]">
          <div class="page-map__collapse" @click="assideShown = !assideShown">
            <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M12.062.065l1.428 1.426-6.494 6.507L.49 1.49 1.917.064l5.078 5.078L12.062.065z" fill="#AEAEB2"/></svg>
          </div>
          <AssistanceAsside
            :provider="$ENUM.ASSISTANCE_PROVIDERS_TYPES.COMPANY"
            @get-requests="getRequests"
            :map-filters="mapFilters"
          />
        </div>
        <MapFooter @filter-by="setMapFilters"/>
      </div>
    </template>
  </div>
</template>

<script>
import AssistanceAsside from '@component/Part/AssistanceAsside';
import ProviderListWithFilters from '@component/Module/ProviderReqestList/ProviderListWithFilters.vue';
import ListMapSwitch from '@component/Part/ListMapSwitch';
import ApplicationsMap from '@component/Widget/ApplicationsMap.vue';
import MapFooter from '@component/Widget/MapFooter.vue';

export default {
  name: 'Applications',
  components: {
    AssistanceAsside,
    ProviderListWithFilters,
    ListMapSwitch,
    ApplicationsMap,
    MapFooter,
  },
  computed: {
    view: {
      get() {
        return this.$store.state.applications.view;
      },
      set(value) {
        this.$store.commit('applications/setView', value);
      },
    },
  },
  data() {
    return {
      dialogSearchRadius: false,
      assideShown: true,
      requests: [],
      mapFilters: {},
    };
  },
  methods: {
    getRequests(requests) {
      this.requests = requests;
    },
    setMapFilters(mapFilters) {
      this.mapFilters = mapFilters;
    },
  },
};
</script>

<style lang="scss" scoped>


.map-switcher {
  position: absolute;
  top: 62px;
  left: 50%;
  padding: 10px 20px 10px;
  transform: translateX(-50%);
  border-radius: 30px;
  background-color: #ffffff;
  z-index: 99;
  @include respond-to('sm') {

      padding: 16px 30px 14px;

  }
}

// map-block
.page-map {
  .page-map__content {
    position: relative;
    .vue-switch {
      position: absolute;
      top: 62px;
      left: 50%;
      padding: 10px 20px 10px;
      transform: translateX(-50%);
      border-radius: 30px;
      background-color: #ffffff;
    }
    .map {
      width: 100%;
      height: 100vh;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .page-map__asside {
    position: fixed;
    right: 0;
    top: 0;
    width: 280px;
    height: 100%;
    background: #FFFFFF;
    box-shadow: 0 3px 25px rgba(151, 161, 176, 0.25);
    transform: translateX(100%);
    will-change: transform;
    transition: transform .2s ease-in-out;
    z-index: 20;
    .page-map__collapse {
      position: absolute;
      right: 100%;
      top: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 46px;
      padding-left: 7px;
      border-radius: 30px 0 0 30px;
      background-color: var(--color-prime);
      transition: all .2s ease-in-out;
      cursor: pointer;
      svg {
        transform: rotateZ(90deg);
        transition: transform .2s ease-in-out;
        path {
          fill: #FFFFFF;
        }
      }
      &:hover {
        background-color: var(--color-prime-active);
      }
    }
    &--shown {
      transform: translateX(0);
      .page-map__collapse {
        svg {
          transform: rotateZ(90deg) rotateX(180deg);
        }
      }
    }
  }
  @include respond-to('sm') {
    .page-map__content {
      .vue-switch {
        padding: 16px 30px 14px;
      }
    }
    .page-map__asside {
      width: 315px;
      .page-map__collapse {
        width: 30px;
        height: 50px;
      }
    }
  }
  @include respond-to('xl') {
    padding-right: 315px;
    .page-map__asside {
      transform: translateX(0);
      .page-map__collapse {
        display: none;
      }
    }
  }
}


</style>
