<template>
  <div class="assistance-asside">
    <vue-custom-scrollbar infinite-wrapper class="custom-scrollbar" :settings="{ wheelPropagation: false }">
      <ProviderListWithFilters
        class="category-asside"
        listMode="asside"
        :provider="provider"
        :map-filters="mapFilters"
        @get-requests="requests => $emit('get-requests', requests)"
      >
      </ProviderListWithFilters>
    </vue-custom-scrollbar>
  </div>
</template>

<script>
import ProviderListWithFilters from '@component/Module/ProviderReqestList/ProviderListWithFilters.vue';
import { ASSISTANCE_PROVIDERS_TYPES } from '@enum/variable';

export default {
  name: 'AssistanceAsside',
  props: {
    provider: {
      type: String,
      required: true,
      validator(value) {
        return Object.keys(ASSISTANCE_PROVIDERS_TYPES).indexOf(value) !== -1;
      },
    },
    mapFilters: {
      type: Object,
      default() {
        return {
          distance: null,
          price_min: null,
        };
      }
    },
  },
  components: {
    ProviderListWithFilters,
  },
};
</script>

<style lang="scss" scoped>
// assistance-asside
.assistance-asside {
  padding-top: 52px;
  .custom-scrollbar {
    max-height: calc(100vh - 52px);
    overflow-y: scroll;
  }
}

// category-asside
.category-asside {
  margin-bottom: 20px;
  padding: 12px 14px 0;

}


</style>
