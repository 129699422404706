<template>
  <div v-on="localListeners" class="vue-switch">
    <div class="vue-switch__label">ВИД:</div>
    <div class="vue-switch__btn">
      <input class="vue-switch__field" type="radio" value="Список" @input="onInput" v-model="innerValue" />
      <div class="vue-switch__cont">
        <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M0 11a1 1 0 001 1h4a1 1 0 100-2H1a1 1 0 00-1 1zM1 0a1 1 0 000 2h16a1 1 0 100-2H1zM0 6a1 1 0 001 1h10a1 1 0 100-2H1a1 1 0 00-1 1z" fill="#0C76DA"/></svg>
        <span>Список</span>
      </div>
    </div>
    <div class="vue-switch__btn">
      <input class="vue-switch__field" type="radio" value="Карта" @input="onInput" v-model="innerValue" />
      <div class="vue-switch__cont">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M.5 17.8c-.1 0-.3-.1-.3-.3V2.4c0-.2.1-.3.2-.3L6 .2l6 2.1L17.4.2h.1c.1 0 .3.1.3.3v15.1c0 .2-.1.3-.2.3L12 17.8l-6-2.1-5.4 2.1H.5zM12.8 3.7v12.1l3.4-1.1V2.4l-3.4 1.3zm-6 10.6l4.4 1.5V3.7L6.8 2.2v12.1zm-5-11v12.3l3.4-1.3V2.2L1.8 3.3z" fill="#000"/></svg>
        <span>Карта</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: 'Список',
    },
  },
  data() {
    return {
      innerValue: this.value,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.innerValue = this.value;
      },
    },
  },
  computed: {
    localListeners() {
      return {
        ...this.$listeners,
        input(e) {},
      };
    },
  },
  methods: {
    onInput(event) {
      this.$emit('input', event.target.value);
    },
  },
};
</script>

<style lang="scss" scoped>
// .vue-switch {
//   position: absolute;
//   top: 62px;
//   left: 50%;
//   padding: 10px 20px 10px;
//   transform: translateX(-50%);
//   border-radius: 30px;
//   background-color: #ffffff;
// }
// @include respond-to('sm') {
//   .vue-switch {
//     padding: 16px 30px 14px;
//   }
// }

// vue-switch
.vue-switch {
  display: flex;
  align-items: center;
  .vue-switch__label {
    color: #000000;
    font-size: 12px;
    font-weight: 500;
    line-height: 1;
    text-transform: uppercase;
  }
  .vue-switch__btn {
    position: relative;
    margin-left: 14px;
    .vue-switch__cont {
      display: flex;
      align-items: center;
      color: #000000;
      font-size: 12px;
      font-weight: 500;
      line-height: 1;
      text-transform: uppercase;
      transition: all 0.2s ease-in-out;
      cursor: pointer;
      svg {
        width: 14px;
        height: auto;
        margin-right: 4px;
        path {
          fill: #000000;
          transition: all 0.2s ease-in-out;
        }
      }
    }
    .vue-switch__field {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      &:checked + .vue-switch__cont {
        color: var(--color-prime);
        svg {
          path {
            fill: var(--color-prime);
          }
        }
      }
    }
  }
  @include respond-to('sm') {
    .vue-switch__label {
      font-size: 13px;
    }
    .vue-switch__btn {
      margin-left: 20px;
      .vue-switch__cont {
        font-size: 13px;
        svg {
          width: 18px;
          margin-right: 8px;
        }
      }
    }
  }
}
</style>
