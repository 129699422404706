<template>
  <div>
    <FiltersBtns
      :provider="provider"
      :responsive="listMode === 'main'"
      :value="selectedRequestStatus"
      @input="setSelectedRequestStatus"
    />
    <ControlPanel>
      <template slot="left">
        <slot name="viewSwitcher"></slot>
      </template>
      <FilterContent
        :is-asside="listMode === 'asside'"
        @applyFilters="onApplyFilters"
        v-model="filters"
        slot="body"
      />
    </ControlPanel>
    <ProviderBaseList
      class="list"
      @infinite="loadNewPage"
      :list="requests"
      :loading="loading"
      :requests-statuses="requestsStatuses"
    >
      <template #list-item="{ item: request }">
        <component
          class="list__item"
          :class="{ list__item_asside: listMode === 'asside' }"
          :request="request"
          :is="listMode === 'asside' ? 'AssideRequestCard' : 'MainRequestCard'"
        >
          <template #footer>
            <FooterBtns>
              <template #action-btn>
                <router-link
                  class="detail-btn"
                  :to="{
                    name: requestDetailsRoute,
                    params: { applicationId: request.id },
                  }"
                >Подробнее</router-link>
              </template>
            </FooterBtns>
          </template>
        </component>
      </template>
    </ProviderBaseList>
  </div>
</template>

<script>
import ProviderBaseList from '@component/Module/ProviderReqestList/ProviderBaseList.vue';
import FiltersBtns from '@component/Module/ProviderReqestList/FiltersBtns.vue';

import ControlPanel from '@component/Module/ControlPanel.vue';
import FilterContent from '@component/Module/FilterContent.vue';
import MainRequestCard from '@component/Module/ProviderReqestList/MainReqestCard.vue';
import AssideRequestCard from '@component/Module/ProviderReqestList/AssideReqestCard.vue';
import FooterBtns from '@component/Module/ProviderReqestList/FooterBtns.vue';

import { ASSISTANCE_PROVIDERS_TYPES } from '@enum/variable';
import { mapState, mapActions, mapGetters } from 'vuex';
import { FILTERS_VALUES } from './enums';

const noramlizeFilters = filters => {
  return {
    ...filters,
    category: filters.category.join(','),
    responsibility: filters.responsibility.join(','),
    // ordering: '-id',
  };
};
export default {
  props: {
    provider: {
      type: String,
      required: true,
      validator(value) {
        return Object.keys(ASSISTANCE_PROVIDERS_TYPES).indexOf(value) !== -1;
      },
    },

    listMode: {
      type: String,
      default: 'main',
      validator(value) {
        return ['main', 'asside'].indexOf(value) !== -1;
      },
    },
    mapFilters: {
      type: Object,
      default() {
        return {
          distance: null,
          price_min: null,
        };
      }
    }
  },
  components: {
    AssideRequestCard,
    FiltersBtns,
    ProviderBaseList,
    ControlPanel,
    FilterContent,
    MainRequestCard,
    FooterBtns,
    // BlockChat: () => import('@component/Module/BlockChat'),
  },
  data() {
    return {
      loading: false,
      requests: [],
      selectedRequestStatus: '',
      pagination: {
        page: 1,
      },
      filters: {
        category: [],
        responsibility: [],
        // ordering: '-created_at',
      },
      pageSize: 10,
    };
  },
  computed: {
    ...mapState('enums', ['requestsStatuses']),
    ...mapState('user', ['user']),
    ...mapGetters('enums', ['services']),
    requestDetailsRoute() {
      return this.provider === this.$ENUM.ASSISTANCE_PROVIDERS_TYPES.PERSONE
        ? this.$routeName.ASSISTANCE_PROVIDE_APPLICATION
        : this.$routeName.ASSISTANCE_PROVIDE_COMPANY_APPLICATION;
    },
  },
  watch: {
    requests: {
      handler(requests) {
        this.$emit('get-requests', requests);
      },
      deep: true,
    },
    mapFilters: {
      handler() {
        this.pagination.page = 1;
        this.loadRequests(true);
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions('enums', ['getRequestsStatuses']),
    async loadRequests(shoudRewriteResponse = false) {
      const parameters = (filters = {}) => {
        return {
          page: this.pagination.page,
          page_size: this.pageSize,
          ...this.mapFilters,
          ...noramlizeFilters({ ...this.filters, ...filters }),
        };
      };

      const requestsTypes = {
        [FILTERS_VALUES.IN_WORK]: async () => {
          if (this.provider === this.$ENUM.ASSISTANCE_PROVIDERS_TYPES.COMPANY) {
            const result = await RequestManager.Company.getRequestsForCompanies(parameters());
            return result;
          }

          const result = await RequestManager.Service.getRequests(this.user.id, {
            status: this.$ENUM.REQUEST_STATUSES.IN_WORK,
            ...parameters({ ordering: '-created_at' }),
          });
          return result;
        },
        [FILTERS_VALUES.RESPONSESE]: async () => {
          if (this.provider === this.$ENUM.ASSISTANCE_PROVIDERS_TYPES.COMPANY) {
            const result = await RequestManager.Company.getResponsesForCompanies(parameters());
            return result;
          }

          const result = await RequestManager.Service.getResponses(this.user.id, {
            ...parameters({ ordering: 'accepted_date' }),
          });
          return result;
        },
        [FILTERS_VALUES.COMPLETED]: async () => {
          if (this.provider === this.$ENUM.ASSISTANCE_PROVIDERS_TYPES.COMPANY) {
            const result = await RequestManager.Company.getRequestsForCompanies({
              status: this.$ENUM.REQUEST_STATUSES.COMPLETED,
              ...parameters(),
            });
            return result;
          }

          const result = await RequestManager.Service.getRequests(this.user.id, {
            status: this.$ENUM.REQUEST_STATUSES.COMPLETED,
            ...parameters({ ordering: '-created_at' }),
          });
          return result;
        },
        '': async () =>
          await RequestManager.WorkRequests.getRequests({
            status: this.$ENUM.REQUEST_STATUSES.ACTIVE,
            ...parameters({ ordering: '-created_at' }),
          }),
      };
      try {
        const requestData = await requestsTypes[this.selectedRequestStatus]();
        if (requestData.length) {
          this.requests.push(...requestData);
        }
        if (shoudRewriteResponse) {
          this.requests = requestData;
        }
        return requestData;
      } catch (error) {
        console.error(error);
      }
    },
    async loadNewPage($state) {
      if (this.requests.length < this.pageSize) {
        $state.complete();
        return;
      }

      this.pagination.page++;
      const requestData = await this.loadRequests();

      if (!requestData || requestData?.length < this.pageSize) {
        $state.complete();
        console.log('complete');
      } else {
        $state.loaded();
        console.log('loaded');
      }
    },
    async onApplyFilters() {
      await this.resetAndLoadReqests();
    },
    async resetAndLoadReqests() {
      this.loading = true;
      this.pagination.page = 1;
      this.requests = [];
      await this.loadRequests();
      this.loading = false;
    },
    async setSelectedRequestStatus(value) {
      this.selectedRequestStatus = value;
      if (this.$route.query?.filters !== value) {
        await this.$router.push({ query: { filters: value } });
      }
      await this.resetAndLoadReqests();
    },
  },
  async created() {
    this.loading = true;
    this.selectedRequestStatus = this.$route.query?.filters ?? '';
    this.user.service.map(({ responsibility_name }) => {
      const service = this.services.find(({ name }) => responsibility_name === name);
      if (service) {
        this.filters.responsibility.push(service.id);
      }
    });
    await this.getRequestsStatuses();
    await this.loadRequests();
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
.list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.list__item {
  width: 100%;

  @include respond-to('sm') {
    width: 49%;
    &_asside {
      width: 100%;
    }
  }
}
.footer-block {
  padding: 9px 0;
  //border-top: 1px solid rgba(163, 163, 163, 0.3);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.chat-button {
  align-items: center;
  display: flex;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: -0.41px;
  color: var(--color-prime);
}
.chat-button__icon {
  padding-right: 7px;
}

.detail-btn {
  font-size: 15px;
  line-height: 22px;
  letter-spacing: -0.41px;
  color: #757579;
}
</style>
