<template>
  <div class="card-request">
    <BaseCard v-bind="$attrs">
      <ApplicationStatus :status="$attrs.status"></ApplicationStatus>
      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
        <slot :name="slot" v-bind="scope"></slot>
      </template>
    </BaseCard>
  </div>
</template>

<script>
import BaseCard from '@component/Module/PersoneCard/BaseCard.vue';
import ApplicationStatus from "@component/UI/ApplicationStatus";
export default {
  inheritAttrs: false,
  components: {
    ApplicationStatus,
    BaseCard,
  },
  props: {
    status: Object,
  },
};
</script>

<style scoped lang="scss">
// card-request
.card-request {
  margin-bottom: 10px;
  background-color: #ffffff;
  border: 1px solid rgba(118, 118, 128, 0.12);
  border-radius: 10px;
  padding: 0px 15px;
  @include respond-to('md') {
    margin-bottom: 40px;
  }
}
</style>
