<template>
  <div class="page-map__footer">
    <v-dialog v-model="isDialogVisible">
      <template v-slot:activator="{ on, attrs }">
        <div class="page-map__link" v-on="on" v-bind="attrs">
          <div class="page-map__link-icon">
            <svg width="11" height="16" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M5.5 0C2.436 0 0 2.48 0 5.6 0 9.76 5.5 16 5.5 16S11 9.76 11 5.6C11 2.48 8.564 0 5.5 0zm0 7.6c-1.1 0-1.964-.88-1.964-2s.864-2 1.964-2 1.964.88 1.964 2-.864 2-1.964 2z" fill="#0C72D6"/></svg>
          </div>
          Радиус поиска заявок
        </div>
      </template>
      <v-card>
        <div class="btn-close" @click="isDialogVisible = false">
          <svg width="12" height="11" viewBox="0 0 12 11" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1.008 9.375a.71.71 0 000 .992.71.71 0 00.992 0L6 6.36l4.008 4.008a.7.7 0 00.984 0 .696.696 0 000-.992l-4-4.008 4-4a.696.696 0 000-.992.7.7 0 00-.984 0L6 4.383 2 .375a.71.71 0 00-.992 0 .71.71 0 000 .992l4.008 4-4.008 4.008z"/></svg>
        </div>
        <ModalSearchRadius @filter-by="filterBy"/>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ModalSearchRadius from '@component/Modal/ModalSearchRadius';

export default {
  components: {
    ModalSearchRadius,
  },
  data() {
    return {
      isDialogVisible: false,
    };
  },
  methods: {
    filterBy(value) {
      this.$emit('filter-by', value);
      this.isDialogVisible = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.page-map__footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 10px 14px;
  text-align: center;
  border-top: 1px solid rgba(#000000, 0.2);
  background-color: #ffffff;
  z-index: 10;
  .page-map__link {
    display: inline-flex;
    align-items: center;
    color: var(--color-prime);
    font-size: 15px;
    line-height: 1;
    transition: color 0.2s ease-in-out;
    cursor: pointer;
    .page-map__link-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      margin-right: 10px;
      border-radius: 50%;
      border: 1px solid rgba(0, 0, 0, 0.1);
      svg {
        path {
          fill: var(--color-prime);
        }
      }
    }
    &:hover {
      color: var(--color-prime-active);
    }
  }
}

@include respond-to('sm') {
  .page-map__footer {
    padding: 25px 16px;
    .page-map__link {
      font-size: 17px;
      .page-map__link-icon {
        width: 35px;
        height: 35px;
      }
    }
  }
}
</style>
