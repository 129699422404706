<template>
  <div class="search-radius">
    <div class="search-radius__head">
      <div class="search-radius__title">Выберите радиус поиска заявок</div>
      <div class="search-radius__text">Для того, чтобы увидеть свои заявки, выберите радиус поиска</div>
    </div>
    <div class="search-radius__block">
      <div class="search-radius__text">Местоположение</div>
      <div class="search-radius__label">Радиус поиска заявок</div>
      <div class="search-radius__slider">
        <v-slider
          max="10000"
          min="500"
          step="50"
          thumb-label="always"
          v-model="radius"
        >
          <template v-slot:thumb-label="item">
            {{item.value / 1000}} км
          </template>
        </v-slider>
      </div>
      <div class="search-radius__descr">
        <span>От 500 м</span>
        <span>До 10 км</span>
      </div>
      <div class="search-radius__text">Каждые 15 мин в случае отсутствия заказов, радиус будет увеличиваться</div>
    </div>
    <div class="search-radius__block">
      <div class="search-radius__text">Заработок</div>
      <div class="search-radius__label">Минимальный размер вознаграждения</div>
      <div class="search-radius__slider">
        <v-slider
          max="10000"
          min="100"
          step="50"
          thumb-label="always"
          v-model="reward"
        >
          <template v-slot:thumb-label="item">
            от {{item.value}} &#8381;
          </template>
        </v-slider>
      </div>
      <div class="search-radius__descr">
        <span>От 100 &#8381;</span>
        <span>До 10 000 &#8381;</span>
      </div>
    </div>
    <div class="btn-group">
      <button type="button" class="btn btn--prime" @click="onMapFilter">Посмотреть заявки</button>
      <button
        v-if="radius > 500 || reward > 100"
        type="button"
        class="btn btn--prime btn--border"
        @click="onReset"
      >Сброс радиуса</button>
    </div>
  </div>
</template>

<script>

export default {
  name: "ModalSearchRadius",
  data() {
    return {
      radius: null,
      reward: null,
    };
  },
  methods: {
    onReset() {
      this.radius = null;
      this.reward = null;
      this.$emit('filter-by', {});
    },
    onMapFilter() {
      this.$emit('filter-by', {
        distance: this.radius / 1000,
        price_min: this.reward,
      });
    },
  }
};

</script>

<style lang="scss" scoped>

// search-radius
.btn-group {
  display: flex;
  justify-content: center;
  margin: 0 -5px;
  // grid-template-columns: repeat(2, 1fr);
  // grid-column-gap: 10px;
}
.search-radius {
  width: 90vw;
  max-width: 484px;
  padding: 36px 14px 20px;
  overflow: hidden;
  .search-radius__head {
    padding: 10px 0 16px;
    text-align: center;
    border-bottom: 1px solid rgba(0,0,0,0.2);
  }
  .search-radius__block {
    padding: 22px 0 12px;
    border-bottom: 1px solid rgba(0,0,0,0.2);
  }
  .search-radius__slider {
    padding: 10px 20px 0;
  }
  .search-radius__title {
    margin-bottom: 12px;
    color: #000000;
    font-weight: 600;
    font-size: 17px;
  }
  .search-radius__label {
    font-size: 17px;
  }
  .search-radius__text {
    margin-bottom: 6px;
    color: #B3B3B8;
    font-size: 15px;
  }
  .search-radius__descr {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    color: #B3B3B8;
    font-size: 13px;
  }
  .btn {
    width: 100%;
    max-width: 343px;
    margin: 26px 5px 0;
    padding-left: 10px;
    padding-right: 10px;
  }
  @include respond-to('sm') {
    padding: 28px 34px;
    overflow: hidden;
    .search-radius__head {
      padding: 10px 0 16px;
    }
    .search-radius__block {
      padding: 22px 0 12px;
    }
    .search-radius__slider {
      padding: 20px 0 0;
    }
    .search-radius__title {
      font-size: 19px;
    }
    .search-radius__label {
      font-size: 19px;
    }
    .search-radius__text {
      margin-bottom: 8px;
      font-size: 17px;
    }
    .search-radius__descr {
      margin-bottom: 12px;
      font-size: 15px;
    }
  }
}

</style>
