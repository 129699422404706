<template>
  <BaseCard
    class="card"
    :item="request"
    :person="request.person"
    :description="request.about"
  >
    <template #body="{ item }">
      <CardItem>
        <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:slot="'icon'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M3.021 6.41L8.785.646l.736.762-6.5 6.5L0 4.887l.762-.762 2.26 2.285z" fill="#0C72D6"/></svg>
        {{ item.category }}
      </CardItem>
      <div class="attrs-row">
        <CardItem :underline="false" class="attrs-row__dist">
          <svg width="11" height="16" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:slot="'icon'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M5.5 0C2.436 0 0 2.48 0 5.6 0 9.76 5.5 16 5.5 16S11 9.76 11 5.6C11 2.48 8.564 0 5.5 0zm0 7.6c-1.1 0-1.964-.88-1.964-2s.864-2 1.964-2 1.964.88 1.964 2-.864 2-1.964 2z" fill="#0C72D6"/></svg>
          {{ item.distance }} км
        </CardItem>
        <CardItem :underline="false">
          <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:slot="'icon'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.421 8.333v.556C10.421 9.5 9.9 10 9.263 10H1.158C.52 10 0 9.5 0 8.889V1.11C0 .5.521 0 1.158 0h8.105c.637 0 1.158.5 1.158 1.111v.556h-5.21c-.637 0-1.158.5-1.158 1.11v4.445c0 .611.52 1.111 1.158 1.111h5.21zm-5.21-1.11H11V2.777H5.21v4.444zm2.315-1.39c-.463 0-.868-.389-.868-.833 0-.444.405-.833.868-.833.463 0 .869.389.869.833 0 .444-.406.833-.869.833z" fill="#0C72D6"/></svg>
          {{ item.price }} руб./день
        </CardItem>
      </div>

      <!-- <CardItem>
        <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:slot="'icon'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.421 8.333v.556C10.421 9.5 9.9 10 9.263 10H1.158C.52 10 0 9.5 0 8.889V1.11C0 .5.521 0 1.158 0h8.105c.637 0 1.158.5 1.158 1.111v.556h-5.21c-.637 0-1.158.5-1.158 1.11v4.445c0 .611.52 1.111 1.158 1.111h5.21zm-5.21-1.11H11V2.777H5.21v4.444zm2.315-1.39c-.463 0-.868-.389-.868-.833 0-.444.405-.833.868-.833.463 0 .869.389.869.833 0 .444-.406.833-.869.833z" fill="#0C72D6"/></svg>
        Цена исполнителя: 700 руб./день
      </CardItem> -->
    </template>

    <template slot="footer">
      <FooterBtns>
        <template #action-btn>
          <v-dialog width="100%" max-width="900px"  v-model="dialogDetails">
            <template v-slot:activator="{ on, attrs }">
              <button
                v-on="on"
                v-bind="attrs"
                type="button"
                class="detail-btn"
              >Подробнее</button>
            </template>
            <vue-custom-scrollbar  class="custom-scrollbar" :settings="{ wheelPropagation: false }">
              <v-card>
                <div class="btn-close" @click="dialogDetails = false">
                  <svg width="12" height="11" viewBox="0 0 12 11" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1.008 9.375a.71.71 0 000 .992.71.71 0 00.992 0L6 6.36l4.008 4.008a.7.7 0 00.984 0 .696.696 0 000-.992l-4-4.008 4-4a.696.696 0 000-.992.7.7 0 00-.984 0L6 4.383 2 .375a.71.71 0 00-.992 0 .71.71 0 000 .992l4.008 4-4.008 4.008z"/></svg>
                </div>
                <ModalProviderReqestDetails :request-id="request.id" />
              </v-card>
            </vue-custom-scrollbar>
          </v-dialog>
        </template>
      </FooterBtns>
    </template>
  </BaseCard>
</template>

<script>
import CardItem from '@component/Module/PersoneCard/CardItem';
import BaseCard from '@component/Module/PersoneCard/BaseCard.vue';
import FooterBtns from '@component/Module/ProviderReqestList/FooterBtns.vue';
import ModalProviderReqestDetails from '@component/Modal/ModalProviderReqestDetails.vue';

export default {
  components: { BaseCard, CardItem, FooterBtns, ModalProviderReqestDetails },
  props: {
    request: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialogDetails: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.card{
  border-bottom: 1px solid rgba(163, 163, 163, 0.3);
  ::v-deep{
    .card-request__head{
      border-bottom: 1px solid rgba(163, 163, 163, 0.3);
    }
  }

}


.attrs-row{
  display: flex;
  border-bottom: 1px solid rgba(163, 163, 163, 0.3);
}
.attrs-row__dist{
  padding-right: 18px;
}

.detail-btn {
  font-size: 15px;
  line-height: 22px;
  letter-spacing: -0.41px;
  color: #757579;
}
.custom-scrollbar {
  max-height: calc(90vh);
  overflow-y: scroll;
}
</style>
