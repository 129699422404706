<template>
  <div class="modal-reqest-details">
    <div v-if="loading">
      <v-progress-linear indeterminate></v-progress-linear>
    </div>
    <ApplicationContent v-else @take-work="loadRequest" :request="request"> </ApplicationContent>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import HelpNeedPersonModel from '@component/Module/HelpNeedPersonModel.js';
import ApplicationContent from '@component/Page/LkAssistanceProvideApplication/ApplicationContent.vue';

export default {
  components: {
    ApplicationContent,
  },
  props: {
    requestId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      request: null
    };
  },
  computed: {
    ...mapState({
      genders: state => state.enums.genders,
    }),
    helpNeedPerson() {
      return new HelpNeedPersonModel(this.request.family, this.genders);
    },
  },
  created() {
    this.loadRequest();
  },
  methods: {
    async loadRequest() {
      try {
        this.loading = true;
        this.request = await RequestManager.WorkRequests.getRequest(this.requestId);
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-reqest-details {
  width: 40rem;
  @media screen and (max-width: 700px) {
    width: 100%;
  }
  padding: 60px 27px 25px;
  .btn {
    width: 100%;
    max-width: 343px;
    margin: 0 auto;
  }
}

</style>
